import _ from 'lodash';
import { createMachine, interpret } from 'xstate';

export const fsm = (config = {}) => {
  const {
    oninit = _.identity,
    onremove = _.identity,
    model = {}, update = {}, fsm = {},
  } = config
  return {
    oninit: vnode => {
      const makecfg = v => _.isFunction(v) ? v(vnode) : v

      let machine = config.machine ?
        makecfg(config.machine) :
        createMachine(makecfg(fsm))
      machine = machine.withContext(makecfg(model))
      machine = machine.withConfig(makecfg(update))
      const service = interpret(machine)
      Object.assign(vnode.state, { machine, service })

      service.start()
      return oninit(vnode)
    },
    onremove: vnode => {
      const { service } = vnode.state

      service.stop()
      return onremove(vnode)
    },
    ..._.omit(config, ['model', 'update', 'machine', 'fsm'])
  }
}
