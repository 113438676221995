import { app, request } from './utils/feathers';

const filter_library = {
  admin: async (user) => {
    const result = await request({ url: '/admin/auth' })
    return result.admin
  }
}

export const authorize = async (user, filters = []) => {
  if (!user) return false
  for(let filter of filters) {
    const allow = await filter_library[filter](user)
    if (!allow) return false
  }
  return true
}