import DOMPurify from 'isomorphic-dompurify';
import _ from 'lodash';
import { elm } from './elm';
import { fsm } from './fsm';
import SelfSufficient from './self-sufficient'

// from https://github.com/orbitbot/awesome-mithril
m.n = (tags, ...rest) => {
  const parts = tags.split('>')
  return parts.reduceRight((child, tag) => m(tag.trim(), child), m(parts.pop().trim(), ...rest))
}

m.link = (selector, ...args) => {
  let attrs = args[0], children = args

  // has attrs
  if (_.isPlainObject(attrs) && !_.isVnode(attrs)) {
    children = args.slice(1)
  } else {
    attrs = {}
  }

  return m(m.route.Link, Object.assign({ selector }, attrs), children)
}

/**
 * HTML sanitizers:
 * native API: https://web.dev/sanitizer/
 * in-browser hacker: https://www.quaxio.com/html_white_listed_sanitizer/
 * DOMPurify: https://github.com/cure53/DOMPurify
 */

m.html = (html, options = { USE_PROFILES: { html: true } }) => {
  return m.trust(DOMPurify.sanitize(html, options))
}

m.route.sethash = (hash, config = {}) => {
  const { replace = true } = config
  if (replace) {
    if (!hash.startsWith('#')) hash = '#' + hash
    history.replaceState(null, '', location.pathname + location.search + hash);
  } else {
    location.hash = hash
  }
}

m.renderdom = (vnode) => {
  const div = document.createElement('div')
  m.render(div, vnode)
  return div.children[0]
}

m.renderhtml = (vnode) => m.renderdom(vnode).outerHTML

const isolate = (sel, compFn) => {
  return (vnode) => {
    let s, redraw = () => s && s.redraw()
    vnode.redraw = redraw
    const component = compFn(vnode)
    return {
      ...component,
      view: vnode => {
        return m(SelfSufficient, {
          root: m(sel),
          view: state => {
            s = state
            return component.view(vnode)
          }
        })
      }
    }
  }
}
_.set(m, 'isolate', isolate)
_.set(m, 'SelfSufficient', SelfSufficient)
_.set(m, 'elm', elm)
_.set(m, 'fsm', fsm)

/**
 * NOTE: mithril does NOT re-render on m.redraw for m.render()
 * But creating a component out of a render fn and mounting it respond to m.redraw.
 * see https://mithril.js.org/mount.html#differences-from-mrender
 * https://stackoverflow.com/questions/46215561/mithril-cannot-m-redraw-with-m-render
 */
const mountfn = (root, renderfn = _.identity) => {
  return m.mount(root, { view: () => renderfn() })
}
_.set(m, 'mountfn', mountfn)