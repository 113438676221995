import feathers from '@feathersjs/feathers'
// import socketio from '@feathersjs/socketio-client'
import rest from '@feathersjs/rest-client'
import authentication from '@feathersjs/authentication-client'
// import analytics from './analytics'
import _ from 'lodash'
// import io from 'socket.io-client'

// const socket = io();
const restClient = rest();
export const app = feathers();

// Set up Socket.io client with the socket
// app.configure(socketio(socket));
app.configure(restClient.fetch(window.fetch))

app.configure(authentication({
  storage: window.localStorage
}));

export const login = () => app.reAuthenticate()
export const logout = async (config = {}) => {
  const { redirect = 'Home' } = config
  await app.logout()
  // analytics.reset()
  app.set('user', null)
  // not setRoute... fresh reload clears out JS memory
  if (redirect) location.href = m.getRoute(redirect)
}

export const oauth = (provider = 'google', opts = {}) => {
  const { redirect = true } = opts
  if (redirect) {
    sessionStorage.redir = m.route.get()
  }
  location.href = `/oauth/${provider}`
}

export const grabToken = () => new Promise(done => {
  return login().then(() => done(true), () => done(false))
}).then(async result => {
  if (result) {
    const { user } = await app.get('authentication');
    app.set('user', user)
    if (sessionStorage.redir) {
      m.route.set(sessionStorage.redir)
      delete sessionStorage.redir
    }
    // analytics.identify(user.id, user)
  } else {
    app.set('user', null)
  }
  return result
})

export const request = async (config) => {
  const token = await app.authentication.getAccessToken()
  const t = token ? `Bearer ${token}` : ''
  _.set(config, 'headers.Authorization', t)
  return m.request(config)
}

if (process.env.NODE_ENV === 'development') {
  window.app = app
}

app.hooks({
  before: {
    all: [context => {
      const { $client } = context.params
      if ($client) {
        _.set(context, 'params.query.$client', $client)
      }
      const special = ['$joins', '$config', '$merge', '$silent']
      special.forEach(key => {
        if (key in context.params) {
          const value = context.params[key]
          _.set(context, ['params', 'query', '$client', key], value)
        }
      })
      return context
    }],
    patch: [context => {
      _.update(context, 'params.query.$client.$merge', val => val === undefined ? true : val)
      return context
    }]
  }
})