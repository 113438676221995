import lozad from 'lozad'

// web perf articles & lazy load libraries:
// https://www.one-tab.com/page/d8pWbnFNQbm5OKUJPbGobA

export const observer = lozad('.lozad', {
  loaded: (el) => {
    const $el = $(el)
    if ($el.is('.evented')) {
      $el.trigger('lozad')
    }
  }
})

export const lazyload = () => {
  observer.observe();

  // Options for the observer (which mutations to observe)
  const domobserver = new MutationObserver(mutationList => {
    for (const mutation of mutationList) {
      const { type, target, attributeName } = mutation
      // if already loaded and we're mutating a lozad attribute, force a reload
      if (type === 'attributes' && target.matches('[data-loaded="true"]') &&
        ['data-src', 'data-srcset', 'data-background-image', 'data-background-image-set', 'data-background-delimiter', 'data-placeholder-background', 'data-iesrc', 'data-alt', 'data-poster', 'data-toggle-class'].includes(attributeName)) {
        target.removeAttribute('data-loaded')
      }
    }
    observer.observe()
  });

  // Start observing the target node for configured mutations
  domobserver.observe(document.body, { attributes: true, childList: true, subtree: true });
}

lazyload()

// equivalent behavior using vanilla-lazyload...
// import LazyLoad from "vanilla-lazyload";
// export const lazyload = () => {
//   const lazyLoadInstance = new LazyLoad({
//     elements_selector: '.lozad',
//     data_bg: 'background-image'
//   });
//   console.log(lazyLoadInstance)
//   lazyLoadInstance.update()

//   // Options for the observer (which mutations to observe)
//   const domobserver = new MutationObserver(mutationList => {
//     lazyLoadInstance.update()
//   });

//   // Start observing the target node for configured mutations
//   domobserver.observe(document.body, { attributes: true, childList: true, subtree: true });
// }
