const site = process.env.SITE_ID || 'main'
// console.log('site', site)

const RouteData = {
  main: {
    '/admin': 'Admin',
    '/': 'Home',
    '/journey': 'Journey',
    '/journey/update': 'JourneyUpdate',
    '/journey/update/:resp_id': 'JournalEntry',
    '/journey/draft/:draft_id': 'JournalEntryDraft',
    '/journey/builder': 'JourneyBuilder',
    '/journey/builder/form': 'FormBuilder',
    '/journey/builder/entry': 'EntryBuilder',
    '/journey/builder/charts': 'ChartsBuilder',
    '/journey/email-preview': 'EmailPreview',
    '/journey/email-compare': 'EmailComparison',
    '/playground': 'Playground',
    '/prisma/renderer': 'PrismaRenderer',
    '/prisma/editor': 'PrismaEditor',
  },
};

module.exports = RouteData[site]