import RouteData from './route-data'
import BiMap from 'bidirectional-map'
import { app, grabToken } from './utils/feathers';
import { authorize } from './authorization';

const RouteMapper = {
  Admin: { authorize: ['admin'], import: () => import('./pages/admin') },
  Home: { import: () => import('./pages/home') },
  Journey: { import: () => import('./pages/journey') },
  JourneyUpdate: { authorize: [], import: () => import('./pages/journey-update') },
  JournalEntry: { authorize: [], import: () => import('./pages/journal-entry') },
  JournalEntryDraft: { import: () => import('./pages/journal-entry') }, // no auth required
  JourneyBuilder: { authorize: [], import: () => import('./pages/builder/journey') },
  FormBuilder: { authorize: [], import: () => import('./pages/builder/form') },
  EntryBuilder: { authorize: [], import: () => import('./pages/builder/entry') },
  ChartsBuilder: { authorize: [], import: () => import('./pages/builder/charts') },
  EmailPreview: { authorize: [], import: () => import('./pages/journal-entry'), property: 'EmailPreview' },
  EmailComparison: { authorize: [], import: () => import('./pages/journal-entry'), property: 'EmailComparison' },
  Playground: { import: () => import('./pages/playground') },
  PrismaRenderer: { import: () => import('./pages/prisma-renderer') },
  PrismaEditor: { import: () => import('./pages/prisma-editor') },
}

// export const onmatch_hooks = []
// for (let hook of onmatch_hooks) {
//   await hook(args, requestedPath)
// }

const Routes = _.mapValues(RouteData, (routeName) => {
  const cmp = RouteMapper[routeName]
  if (cmp.import) {
    return {
      onmatch: async (args, requestedPath) => {
        if (!app.get('user')) await grabToken()

        const user = app.get('user')
        if (cmp.authorize && !(await authorize(user, cmp.authorize))) {
          return m.setRoute('Home')
        }

        return cmp.import().then(m => m[cmp.property || 'default'])
      }
    }
  } else {
    return { render: vnode => m(cmp) };
  }
});

const DefaultRoute = '/'
m.route.prefix = ""

const routemap = new BiMap(RouteData)
m.getRoute = (route, query = {}) => {
  const path = routemap.getKey(route)
  return m.buildPathname(path, query)
}
m.setRoute = (route, query = {}) => {
  return m.route.set(m.getRoute(route, query))
}
m.setParam = (query = {}, config = {}) => {
  const { merge = true } = config
  if (merge) query = _.merge({}, m.route.param(), query)
  return m.route.set(m.buildPathname(location.pathname, query))
}

export { Routes, DefaultRoute };