document.addEventListener('DOMContentLoaded', () => {
  /**
   * modal handler
   */

  // Functions to open and close a modal
  function openModal($el) {
    $el && $el.classList.add('is-active');
  }

  function closeModal($el) {
    ($el && !$el.matches('.manual')) && $el.classList.remove('is-active');
  }

  function closeAllModals() {
    (document.querySelectorAll('.modal') || []).forEach(($modal) => {
      closeModal($modal);
    });
  }

  document.addEventListener('click', e => {
    // Add a click event on buttons to open a specific modal
    if (e.target.matches('.js-modal-trigger')) {
      const $trigger = e.target
      const modal = $trigger.dataset.target;  // data-target attribute
      const $target = document.getElementById(modal);
      openModal($target);
    }

    if (e.target.matches('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button')) {
      const $close = e.target
      const $target = $close.closest('.modal');
      closeModal($target);
    }

    /**
     * navbar mobile menu handler
     */
    const burger = e.target.closest('.navbar-burger')
    if (burger) {
      const el = burger
      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el && el.classList.toggle('is-active');
      $target && $target.classList.toggle('is-active');
    }


    /**
     * delete notification
     */
    if (e.target.matches('.notification .delete')) {
      const $delete = e.target
      const $notification = $delete.parentNode;
      $notification.parentNode.removeChild($notification);
    }

  })

  // Add a keyboard event to close all modals
  document.addEventListener('keydown', (event) => {
    const e = event || window.event;

    if (e.key === 'Escape') {
      closeAllModals();
    }
  });
});