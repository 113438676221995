export const listen = (vnode, [node, ...args]) => {
  node.addEventListener(...args)
  _.update(vnode.state, 'handlers', (handlers = []) => {
    handlers.push([node, ...args])
    return handlers
  })
}

export const unlisten = (vnode) => {
  const { handlers = [] } = vnode.state
  handlers.forEach(([node, ...args]) => {
    node.removeEventListener(...args)
  })
}

export const unlistenHook = () => unlisten