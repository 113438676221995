import _ from 'lodash';
import asyncPool from 'tiny-async-pool'

_.mixin({
  timeout: ms => new Promise(done => setTimeout(done, ms)),
  nest2dot: o => {
    const out = {}
    const inner = (o, prefix = '') => {
      if (_.isPlainObject(o) || _.isArray(o)) {
        for (let k in o) {
          inner(o[k], prefix ? prefix + '.' + k : k)
        }
      } else {
        out[prefix] = o
      }
    }
    inner(o)
    return out
  },
  dot2nest: o => {
    const out = {}
    for (let k in o) {
      _.set(out, k, o[k])
    }
    return out
  },
  makeperm: (perm = [], len = perm.length) => {
    return _.uniq(perm
      .filter(idx => _.isNumber(idx) && idx >= 0 && idx < len)
      .concat(_.range(len)))
  },
  permute: (arr, perm = []) => {
    return _.makeperm(perm, arr.length).map(idx => arr[idx])
  },
  raf: () => new Promise(done => requestAnimationFrame(done)),
  isVnode: x => typeof x !== "object" || x.tag != null || Array.isArray(x),
  devlog: (...args) => {
    if (process.env.NODE_ENV !== 'production') console.log(...args)
  },
  roundNearest: (n, base) => Math.round(n / base) * base,
  neq: _.negate(_.eq),
  isEqualMap: (a, b, mapper) => _.isEqual(...[a, b].map(mapper)),
  mapcat: (collection, iteratee) => _.flatten(_.map(collection, iteratee)),
  dbg: (o, name) => {
    console.log(name, o)
    if (name) window[name] = o
    return o
  },
  pp: json => {
    console.log(JSON.stringify(json, null, 2))
    return json
  }
});

// https://javascript.info/promisify
const promisify = (f, config = {}) => {
  return function (...args) { // return a wrapper-function (*)
    return new Promise((resolve, reject) => {
      args.push((err, result) => {
        if (config.noerror) return resolve(err)
        if (err) return reject(err);
        return resolve(result);
      });

      f.call(this, ...args); // call the original function
    });
  };
}

_.mixin({ promisify })

_.mixin({
  interpose: (array, el) => [].concat(...array.map(e => [el, e])).slice(1)
})

_.mixin({
  pmap: (arr, mapper, options = {}) => {
    const { concurrency = arr.length } = options
    const limit = _.clamp(concurrency, 1, arr.length)
    return asyncPool(limit, arr, mapper)
  },
  pall: (asyncThunks, options = {}) => _.pmap(asyncThunks, x => x(), options)
})


// https://stackoverflow.com/questions/31683075/how-to-do-a-deep-comparison-between-2-objects-with-lodash
const deepDiff = (a, b) => {

  const result = {
    different: [],
    missing_from_first: [],
    missing_from_second: []
  };

  _.reduce(a, (result, value, key) => {
    if (b.hasOwnProperty(key)) {
      if (_.isEqual(value, b[key])) {
        return result;
      } else {
        if (typeof (a[key]) != typeof ({}) || typeof (b[key]) != typeof ({})) {
          //dead end.
          result.different.push(key);
          return result;
        } else {
          const deeper = deepDiff(a[key], b[key]);
          result.different = result.different.concat(_.map(deeper.different, (sub_path) => {
            return key + "." + sub_path;
          }));

          result.missing_from_second = result.missing_from_second.concat(_.map(deeper.missing_from_second, (sub_path) => {
            return key + "." + sub_path;
          }));

          result.missing_from_first = result.missing_from_first.concat(_.map(deeper.missing_from_first, (sub_path) => {
            return key + "." + sub_path;
          }));
          return result;
        }
      }
    } else {
      result.missing_from_second.push(key);
      return result;
    }
  }, result);

  _.reduce(b, (result, value, key) => {
    if (a.hasOwnProperty(key)) {
      return result;
    } else {
      result.missing_from_first.push(key);
      return result;
    }
  }, result);

  return result;
}

_.mixin({ deepDiff })

const slugify = (text) => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

_.mixin({ slugify })

const parseurl = (url) => {
  const a = document.createElement('a')
  a.href = url
  return _.pick(a, ['origin', 'protocol', 'username', 'password', 'host', 'hostname', 'pathname', 'search', 'hash', 'href'])
}

_.mixin({ parseurl })