import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* Import all vendor.scss / css here[e.g. Import 'font-awesome/scss/font-awesome.scss';] */
import { Routes, DefaultRoute } from './routes';

/* Include global app styles here, so that it will over ride component's css styles*/
import './app.scss';

if (module.hot) {
    module.hot.accept();
}

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!');
}

console.log('Env var test ===>', process.env.BASE_URL, process.env.BASE_URL_EXPAND);

import './utils/mithril/index'
import './utils/lodash-mixins'
import './utils/bulma'
import './utils/modal-popups'
import './utils/analytics'
import './utils/lazyload';
// import './utils/fsload';
// Wire up mithril app to DOM
const $root = document.body.querySelector('#root');
m.route($root, DefaultRoute, Routes);
