import _ from 'lodash';

export const elm = (config = {}) => {
  const { oninit = _.identity } = config
  const elm_cfgs = ['model', 'update', 'machine', 'service']
  return {
    ..._.omit(config, elm_cfgs),
    oninit: vnode => {
      elm_cfgs.forEach(cfg => {
        const value = config[cfg]
        vnode.state[cfg] = _.isFunction(value) ? value(vnode) : value
      })
      return oninit(vnode)
    }
  }
}