import _ from "lodash"
import queue from 'async/queue';
import { listen, unlistenHook } from "../components/listener";

const $modal = $(`<div id="modal-popup" class="manual modal"></div>`)
$modal.appendTo('body').css('zIndex', Number.MAX_SAFE_INTEGER)
const modal = $modal.get(0)

// use a queue
const q = queue((asyncThunk, done) => asyncThunk().then(done), 1);

const makeModal = (vnode, config = {}) => {
  const { title, mode, done, defaultText, clickout_cancel, labels = {} } = config
  let result = undefined
  const finish = e => {
    $modal.removeClass('is-active')
    done(result)
    if (mode === 'prompt') $modal.find('input').val('')
  }
  const focus = vnode => {
    mode === 'prompt' ?
      vnode.dom.querySelector('input').focus() :
      vnode.dom.querySelector('button[type="submit"]').focus()
  }
  const dismiss = e => {
    if (e.key === 'Escape') {
      const reset = $modal.find('form button[type="reset"]').get(0)
      reset && reset.click()
    }
  }
  addEventListener('keydown', dismiss, { once: true })
  return [
    m('.modal-background', {
      onclick: e => {
        if (!clickout_cancel) return
        if (mode === 'confirm') result = false
        if (mode === 'prompt') result = null
        finish(e)
      }
    }),
    m('.modal-content',
      m("article.message", { class: config.class },
        title && m(".message-header", title),
        m("form.message-body", {
          oncreate: focus,
          onupdate: focus,
          onsubmit: e => {
            e.preventDefault()
            if (mode === 'confirm') result = true
            finish(e)
          },
          onreset: e => {
            e.preventDefault()
            if (mode === 'confirm') result = false
            if (mode === 'prompt') result = null
            finish(e)
          }
        },
          vnode,
          mode === 'prompt' && m('input.input.my-4', {
            defaultValue: defaultText,
            oncreate: async vnode => {
              result = defaultText
              vnode.dom.select()
              vnode.dom.focus()
            },
            oninput: e => result = e.target.value
          }),
          m.n('nav.level', m('.level-right > .level-item.buttons',
            mode !== 'alert' && m('button.button.is-rounded', { type: 'reset', }, labels.cancel || 'Cancel'),
            m('button.button.is-primary.is-rounded', { type: 'submit', }, labels.ok || 'Ok'),
          ))
        )
      ),
    )
  ]
}

const render_to_modal = (config = {}) => {
  const { renderbody, body } = config
  if (renderbody) {
    m.mountfn(modal, () => makeModal(renderbody(), config))
  } else {
    m.mount(modal, null)
    m.render(modal, makeModal(body, config))
  }
}

const popup_promise = (config={}, mode) => new Promise(done => {
  $modal.addClass('is-active')
  render_to_modal({ ...config, mode, done })
})

/**
 * USAGE:
 * pass config with title, body (vnode), and optional clickout_cancel (bool)
 * for prompt, pass an optional defaultText string
 */
const qpush = _.promisify(q.push, { noerror: true })
export const alert = (config = {}) => qpush(() => popup_promise(config, 'alert'))
export const confirm = (config = {}) => qpush(() => popup_promise(config, 'confirm'))
export const prompt = (config = {}) => qpush(() => popup_promise(config, 'prompt'))


// const makeCustomModal = (config = {}) => {
//   const { escape_cancel = true, clickout_cancel, render } = config
//   const close = () => {
//     $modal.removeClass('is-active')
//     escape_cancel && removeEventListener('keydown', dismiss)
//   }
//   const dismiss = e => {
//     if (e.key === 'Escape') close()
//   }
//   escape_cancel && addEventListener('keydown', dismiss)
//   return [
//     m('.modal-background', {
//       onclick: e => {
//         if (!clickout_cancel) return
//         close()
//       }
//     }),
//     m('.modal-content', render({ close }))
//   ]
// }
// export const custom = (config = {}) => {
//   $modal.addClass('is-active')
//   m.render(modal, makeCustomModal(config))
// }

export const Modal = ({ redraw = m.redraw }) => {
  return {
    oncreate: vnode => {
      const { onclose = _.identity, escclose = true } = vnode.attrs
      listen(vnode, [window, 'keydown', e => {
        if (escclose && e.key === 'Escape') onclose()
      }])
    },
    onremove: unlistenHook(),
    view: (vnode) => {
      const { btnclose = true, bgclose, onclose = _.identity } = vnode.attrs
      return m('.modal.manual.is-active',
        m('.modal-background', { onclick: () => bgclose && onclose() }),
        m('.modal-content.is-relative',
          btnclose && m('button.delete[type="button"]', {
            style: 'position: absolute; top: 0.75rem; right: 0.75rem;',
            onclick: onclose
          }),
          vnode.children
        )
      )
    }
  }
}
